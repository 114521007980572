import PublicLayout from '@/components/v1/layouts/PublicLayout.jsx';
import Container from '../../../components/v1/Container.jsx';
import asset from '@/utils/VaporUtil.js';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { Head } from '@inertiajs/react';
import ApplicationWidget from '@/components/v1/widgets/ApplicationWidget.jsx';

const Register = ({ presets, default_values, source }) => {
    return (
        <PublicLayout>
            <Head title={`Financieringsaanvraag`} />

            <section className={'bg-primary-500 py-10 xl:py-20'}>
                <Container className={'grid max-w-screen-2xl grid-cols-1 items-center gap-20 xl:grid-cols-2'}>
                    <ApplicationWidget presets={presets} source={source} defaultValues={default_values} />

                    <div className={'hidden xl:block'}>
                        <img src={asset('assets/images/house.png')} />
                    </div>
                </Container>
            </section>

            <Container className={'max-w-screen-2xl'}>
                <section className={'py-10 xl:py-20'}>
                    <div className={'flex flex-col gap-10 rounded-lg bg-beige-900 p-10 xl:p-20'}>
                        <div className={'flex flex-col gap-2'}>
                            <h2 className={'text-center text-2xl font-bold'}>Heeft u vragen over de mogelijkheden?</h2>

                            <p className={'text-center'}>Bel direct of stuur een e-mail. Wij nemen dezelfde dag nog contact met u op.</p>
                        </div>

                        <div className={'mx-auto flex w-fit flex-col items-center gap-10 lg:flex-row'}>
                            <a href={'tel:+310732032210'}>
                                <div className={'flex items-center gap-2'}>
                                    <PhoneIcon className={'aspect-square h-6 text-black'} />
                                    <p className={'text-xl text-black'}>+31 (0)73 203 22 10</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>
            </Container>
        </PublicLayout>
    );
};

export default Register;
